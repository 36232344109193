import axios from 'axios'
import nanoid from 'nanoid'
import Fingerprint2 from 'fingerprintjs2'
import hashObject from 'object-hash'

const USER_IDENTIFIER_MAUTIC = 'mautic'
const USER_IDENTIFIER_FINGERPRINTJS = 'fingerprintjs2'

export const MESSAGE_INPUT = 'input'
export const MESSAGE_CHECKBOX = 'checkbox'
export const MESSAGE_MAP = 'map'
export const MESSAGE_TABLE = 'table'
export const MESSAGE_BUTTON = 'button'
export const MESSAGE_IMAGE = 'image'
export const MESSAGE_HTML = 'html'
export const MESSAGE_EMOJI = 'smiley'
export const MESSAGE_AUTOFILL = 'autofill'
export const MESSAGE_GEOLOC = 'geoloc'
export const MESSAGE_CAROUSEL = 'carousel'

export const MESSAGE_FROM_USER = 'user'

export const POSTING = 'POSTING'
export const POST_FAILED = 'POST_FAILED'
export const POST_SUCCESS = 'POST_SUCCESS'
export const REMOVE_LAST_MESSAGE = 'REMOVE_LAST_MESSAGE'
export const SET_CONFIG = 'SET_CONFIG'
export const USER_SAID = 'USER_SAID'

export const SET_LOGS_META = 'SET_LOGS_META'

// -------------------------------------------------------- getSessionId() --
async function getSessionId(config) {
  if (typeof config.identifier.use === 'function') {
    return config.identifier.use().toString()
  } else {
    switch (config.identifier.use) {
      case USER_IDENTIFIER_MAUTIC: {
        /* eslint-disable */
        ;(function(w, d, t, u, n, a, m) {
          w['MauticTrackingObject'] = n
          ;(w[n] =
            w[n] ||
            function() {
              ;(w[n].q = w[n].q || []).push(arguments)
            }),
            (a = d.createElement(t)),
            (m = d.getElementsByTagName(t)[0])
          a.async = 1
          a.src = u
          m.parentNode.insertBefore(a, m)
        })(window, document, 'script', config.identifier.mautic.src, 'mt')

        /* eslint-disable */
        mt('send', 'pageview')

        let id = document.cookie.split(';')[0].split('=')[1]

        if (typeof id === 'undefined') {
          setTimeout(() => window.location.reload(), config.identifier.mautic.timeout)
        }

        return id
      }

      case USER_IDENTIFIER_FINGERPRINTJS: {
        return await new Promise(resolve => {
          setTimeout(function() {
            Fingerprint2.get(function(components) {
              resolve(hashObject(components))
            })
          }, config.identifier.fingerprintjs2.timeout)
        })
      }

      default:
        throw new Error(`Unsupported user identifier '${config.identifier.use}'`)
    }
  }
}

// ----------------------------------------------------- userSaidAndPost() --
export const userSaidAndPost = (user_said_params, post_params) => (dispatch, getState) => {
  dispatch(userSaid(user_said_params))

  setTimeout(() => {
    dispatch(post(post_params))
  }, getState().config.animation_timeouts.wait_between_user_request_and_bot_response_ms)
}

// ------------------------------------------------------------ userSaid() --
export const userSaid = (message, remove_last_message = true) => dispatch => {
   // console.log('user said reached clicked----------------')
  if (remove_last_message) {
    dispatch({ type: REMOVE_LAST_MESSAGE })
  }

  dispatch({
    type: USER_SAID,
    payload: {
      key: nanoid(),
      from: 'user',
      data: [
        {
          from: MESSAGE_FROM_USER,
          key: nanoid(),
          text: message,
        },
      ],
    },
  })
}

export const userSaidWithoutRemovingLastMessageAndPost = (user_said_params, post_params) => (dispatch, getState) => {
  dispatch(userSaid(user_said_params, false))
  // console.log('user clicked userSaidWithoutRemovingLastMessageAndPost----------------')
  setTimeout(() => {
    dispatch(post(post_params))
  }, getState().config.animation_timeouts.wait_between_user_request_and_bot_response_ms)
}

// when use click disliked
export const userReacted = (user_said_params,post_params) => (dispatch, getState) => {
  // console.log('userReacted----------------')
  // dispatch(userSaid(user_said_params, false))
  setTimeout(() => {
    dispatch(post(post_params))
  }, getState().config.animation_timeouts.wait_between_user_request_and_bot_response_ms)
}

export const postAndRemoveLastMessage = data => dispatch => {
  dispatch({ type: REMOVE_LAST_MESSAGE })
  dispatch(post(data))
}

// ---------------------------------------------------------------- post() --
export const post = data => (dispatch, getState) =>
  (async (content, dispatch, getState) => {

    if(content.hasOwnProperty('isReaction') && content.isReaction==true){
      // console.log('dump content');
    }else{
      dispatch({ type: POSTING })
    }

    const payload = {
      ...content,
      lang: getState().config.language,
      sessionId: await getSessionId(getState().config),
    }

    let { data } = await axios.post(getState().config.post_url, payload)

    if (_.hasIn(data, 'logs_meta') && data.logs_meta.is_stib) {
      dispatch({
        type: SET_LOGS_META,
        payload: data.logs_meta
      })
    }

    if (_.hasIn(data, 'queryResult.fulfillmentMessages')) {

      let intent = data.queryResult.intent;
      let sentQuery = data.queryResult.queryText;
      let metadata = data.logs_meta;
      let lngs = {'fr' : 'Cela répond à votre question?', 'nl': 'Beantwoordt dat jouw vraag?', 'en': 'Does that answer your question?'};
      let languageCode = data.queryResult.languageCode;
      let hasBtn = false;
      data = data.queryResult.fulfillmentMessages.map(item => {
        if(item.payload.hasOwnProperty('buttons')){
          // console.log('item payload buttons', item.payload.buttons);
          let btns = item.payload.buttons;
          if(btns.length>0) hasBtn = true;
        }
        if (!('key' in item)) {
          item.key = nanoid()
          item.intent = intent
          item.sentQuery = sentQuery
          item.metadata = metadata
          item.hasBtn = hasBtn
          item.phrase = lngs[languageCode]
        }

        return item
      })

      dispatch({
        type: POST_SUCCESS,
        payload: {
          key: nanoid(),
          data,
        },
      })
    } else {
      dispatch({
        type: POST_FAILED,
        error_type: status.errorType,
        error_details: status.errorDetails,
      })
    }
  })(data, dispatch, getState)
